<template>
  <div class="mt-4">
     <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4">
      <div style="height: 150px;" class="relative shadow cursor-pointer rounded-2xl card p-4 bg-white mt-10" @click.stop="$router.push({name: 'OrgDetail'})" >
        <p class="text-right font-bold text-primary">
          Company Details
        </p>
        <div class="card_icon bg-primary flex justify-center items-center">
        <i style="font-size: 35px; color: white;" :class="'fa-solid fa-house-chimney-user'"></i>
        </div>
      </div>
      <div style="height: 150px;" class="relative shadow cursor-pointer rounded-2xl card p-4 bg-white mt-10" @click.stop="$router.push({name: 'SettingStorage'})" >
        <p class="text-right font-bold text-primary">
          Manage Storage
        </p>
        <div class="card_icon bg-primary flex justify-center items-center">
        <i style="font-size: 35px; color: white;" :class="'fa-solid fa-bucket'"></i>
        </div>
      </div>
      <div style="height: 150px;" class="relative shadow cursor-pointer rounded-2xl card p-4 bg-white mt-10" @click.stop="clickOnEmp()" >
        <p class="text-right font-bold text-primary">
          Employee
        </p>
        <div class="card_icon bg-primary flex justify-center items-center">
        <i style="font-size: 35px; color: white;" :class="'fa-solid fa-user-tie'"></i>
        </div>
      </div>
      <div style="height: 150px;" class="relative shadow cursor-pointer rounded-2xl card p-4 bg-white mt-10" @click="$router.push({name: 'Items'})">
        <p class="text-right font-bold text-primary">
          Items
        </p>
        <div class="card_icon bg-primary flex justify-center items-center">
        <i style="font-size: 35px; color: white;" :class="'fa-solid fa-box'"></i>
        </div>
      </div>
      <div style="height: 150px;" class="relative shadow cursor-pointer rounded-2xl card p-4 bg-white mt-10" @click="$router.push({name: 'SettingPaymentMethod'})">
        <p class="text-right font-bold text-primary">
          Payment Method
        </p>
        <div class="card_icon bg-primary flex justify-center items-center">
        <i style="font-size: 35px; color: white;" :class="'fa-solid fa-money-check-dollar'"></i>
        </div>
      </div>
      <div style="height: 150px;" class="relative shadow cursor-pointer rounded-2xl card p-4 bg-white mt-10" @click="$router.push({name: 'AccountingCode'})">
        <p class="text-right font-bold text-primary">
          Expense Categories
        </p>
        <div class="card_icon bg-primary flex justify-center items-center">
        <i style="font-size: 35px; color: white;" :class="'fa-solid fa-calculator'"></i>
        </div>
      </div>
      <div style="height: 150px;" class="relative shadow cursor-pointer rounded-2xl card p-4 bg-white mt-10" @click="$router.push({name: 'SettingTax'})">
        <p class="text-right font-bold text-primary">
          Taxes
        </p>
        <div class="card_icon bg-primary flex justify-center items-center">
        <i style="font-size: 35px; color: white;" :class="'fa-solid fa-percent'"></i>
        </div>
      </div>
      <div style="height: 150px;" class="relative shadow cursor-pointer rounded-2xl card p-4 bg-white mt-10" @click="$router.push({name: 'SettingTools'})">
        <p class="text-right font-bold text-primary">
          Tools
        </p>
        <div class="card_icon bg-primary flex justify-center items-center">
        <i style="font-size: 35px; color: white;" :class="'fa-solid fa-screwdriver-wrench'"></i>
        </div>
      </div>
      <div style="height: 150px;" class="relative shadow cursor-pointer rounded-2xl card p-4 bg-white mt-10" @click="$router.push({name: 'SettingJobCategory'})">
        <p class="text-right font-bold text-primary">
          Job Category
        </p>
        <div class="card_icon bg-primary flex justify-center items-center">
        <!-- <i style="font-size: 35px; color: white;" :class="'fa-solid fa-screwdriver-wrench'"></i> -->
        <svg data-v-0a6a92e8="" class="svg-inline--fa fa-briefcase" style="height: 30px; width: 30px;" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="briefcase" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="#ffffff" d="M184 48H328c4.4 0 8 3.6 8 8V96H176V56c0-4.4 3.6-8 8-8zm-56 8V96H64C28.7 96 0 124.7 0 160v96H192 320 512V160c0-35.3-28.7-64-64-64H384V56c0-30.9-25.1-56-56-56H184c-30.9 0-56 25.1-56 56zM512 288H320v32c0 17.7-14.3 32-32 32H224c-17.7 0-32-14.3-32-32V288H0V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V288z"></path></svg>
        </div>
      </div>
      <div style="height: 150px;" class="relative shadow cursor-pointer rounded-2xl card p-4 bg-white mt-10" @click="$router.push({name: 'SettingJobType'})">
        <p class="text-right font-bold text-primary">
          Job Type
        </p>
        <div class="card_icon bg-primary flex justify-center items-center">
        <i style="font-size: 35px; color: white;" :class="'fas fa-tags'"></i>
        </div>
      </div>
      <div style="height: 150px;" class="relative shadow cursor-pointer rounded-2xl card p-4 bg-white mt-10" @click="$router.push({name: 'SettingNotesCategory'})">
        <p class="text-right font-bold text-primary">
          Note Categories
        </p>
        <div class="card_icon bg-primary flex justify-center items-center">
        <i style="font-size: 35px; color: white;" :class="'fas fa-solid fa-file-pen'"></i>
        </div>
      </div>
      <div style="height: 150px;" class="relative shadow cursor-pointer rounded-2xl card p-4 bg-white mt-10" @click="$router.push({name: 'SettingLocation'})">
        <p class="text-right font-bold text-primary">
          Location
        </p>
        <div class="card_icon bg-primary flex justify-center items-center">
        <i style="font-size: 35px; color: white;" :class="'fas fa-map-marker-alt'"></i>
        </div>
      </div>
      <div style="height: 150px;" class="relative shadow cursor-pointer rounded-2xl card p-4 bg-white mt-10" @click="$router.push({name: 'Twilioconfiguration'})">
        <p class="text-right font-bold text-primary">
          Twilio Configuration
        </p>
      <div class="card_icon bg-primary flex justify-center items-center">
        <i style="font-size: 35px; color: white;" :class="'fas fa-message'"></i>
        </div>
      </div>
      <div style="height: 150px;" class="relative shadow cursor-pointer rounded-2xl card p-4 bg-white mt-10" @click="$router.push({name: 'Emailconfiguration'})">
        <p class="text-right font-bold text-primary">
          Email Configuration
        </p>
        <div class="card_icon bg-primary flex justify-center items-center">
        <i style="font-size: 35px; color: white;" :class="'fas fa-envelope'"></i>
        </div>
      </div>
      <!-- <div style="height: 150px;" class="relative shadow cursor-pointer rounded-2xl card p-4 bg-white mt-10" @click="$router.push({name: 'CheckListItem'})">
        <p class="text-right font-bold text-primary">
          Check List
        </p>
        <div class="card_icon bg-primary flex justify-center items-center">
        <i style="font-size: 35px; color: white;" :class="'fa-solid fa-list-check'"></i>
        </div>
      </div> -->
    </div>
   </div>
</template>
<script>
import {FilterPermissions} from '@/utils/Permissions.js'

export default {
  name: "Setting",
  components: {
  },
  data() {
    return {
      isEmployeePermission: FilterPermissions('employee')
    };
  },
  created() {},
  mounted() {},
  beforeDestroy() {
  },
  methods: {
    clickOnEmp () {
      this.isEmployeePermission = FilterPermissions('employee')
      setTimeout(() => {
        if (this.isEmployeePermission) {
          this.$router.push({name: 'SettingEmployees'})
        } else {
          this.$store.dispatch('ShowSubPopup', {status: true, title: `You don't have permission to open Employee`})
        }
      }, 100);
    }
  }
};
</script>
<style scoped>
.card_icon{
    position: absolute;
    left: 20px;
    top: -30px;
    border-radius: 8%;
    width: 80px!important;
    height: 80px!important;
    text-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
</style>